import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AuthenticateResourceService, CommonAlertService, DiagnosticType, JwtService, Roles, SelfDiagnosticService } from 'src/app/core';
import { IEmployeeReportlist } from 'src/app/core/models/employee-micro-coach-report-list.model';
import { IQuestionnaireResults } from 'src/app/core/models/questionnaire-results.model';
import { IUser } from 'src/app/core/models/user.model';
import { ReportsService } from 'src/app/core/services/reports.service';
import { ErrorModalComponent } from 'src/app/shared/components/error-modal/error-modal.component';

@Component({
    selector: 'app-side-navigation',
    templateUrl: './side-navigation.component.html',
    styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit {
    @Input() public adminUser?: boolean;
    public sideNavigationExtended = false;
    public navOption = '';
    public collapseOption = '';
    public userCurrentStage?: number;
    public diagnosticResultsActive = false;
    public reportsActive = false;
    public isLoading = false;
    public completedQuestionnaireList: Array<IQuestionnaireResults> | undefined = [];
    public reloadCompletedQuestionnaireList = false;
    public reloadReportList = false;
    /* eslint-disable */
    public user: IUser | undefined | null | any = {};
    /* eslint-enable */
    public reportList: IEmployeeReportlist | undefined;
    public userRole?: Roles = Roles.EventCreator;

    public constructor(
        private readonly _router: Router,
        private readonly _selfDiagnostic: SelfDiagnosticService,
        private readonly _report: ReportsService,
        private readonly _authenticateResourceService: AuthenticateResourceService,
        private readonly _modal: NzModalService,
        private readonly _jwtService: JwtService,
        private readonly _commonAlertService: CommonAlertService
    ) {}

    public ngOnInit(): void {
        if (localStorage.getItem('user-current-stage')) {
            this.userCurrentStage = Number(localStorage.getItem('user-current-stage'));
        }

        const storedUser = localStorage.getItem('user');
        if (storedUser !== null) {
            this.user = JSON.parse(storedUser);
            console.log("this.user: ", this.user);
            

            if (this.user) {
                this.userRole = this.user.role;
            }
        }
    }

    public expandSideNavigation(option: string): void {
        if (this.navOption === option) {
            this.sideNavigationExtended = !this.sideNavigationExtended;
        } else {
            this.navOption = option;
            this.sideNavigationExtended = true;
        }
    }

    public async logout() {
        this.isLoading = true;

        try {
            this._jwtService.logout();
            await this._router.navigate(['/auth/login']);
            this._commonAlertService.popup("Success", "Successfully signed out");
        } catch (error) {
            this.errorModal('Failed to log out. Please try again.', false);
        }

        this.isLoading = false;
    }

    public errorModal(errorMessage: string, reload: boolean): void {
        this._modal.create({
            nzContent: ErrorModalComponent,
            nzComponentParams: {
                errorMessage,
                reload
            },
            nzCentered: true,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false
        });
    }

    public async routeTo(route: string) {
        await this._router.navigate([route]);
    }
}
