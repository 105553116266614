import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {AgmMap, LatLngBounds, MapsAPILoader} from "@agm/core";
import {IMapMarker} from "../../../core/models/map-marker.model";
import {IMapOptions} from "../../../core/models/map-options.model";

@Component({
  selector: 'app-select-location',
  templateUrl: './select-location.component.html',
  styleUrls: ['./select-location.component.scss'],
})
export class SelectLocationComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
  @Input()
  public options: IMapOptions = {
    center: {lat: 22.333, lng: 33.2344},
    zoom: 1,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
  };
  @Input()
  public markers?: Array<IMapMarker | undefined>;
  @Input()
  public connections?: Array<Array<google.maps.LatLngLiteral>>;
  @Input()
  public showSearch?: boolean;
  @Input()
  public searchTerm?: string;
  @Input()
  public showApplyMarker?: boolean;
  @Input()
  public showClearMarker?: boolean;
  @ViewChild('searchMapInput', {static: false})
  public searchBox?: ElementRef<HTMLInputElement>;
  @ViewChild('map', {static: false})
  public map?: AgmMap;
  @Output()
  public placeSelected: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter<google.maps.places.PlaceResult>();

  @Output()
  public mapClick: EventEmitter<google.maps.MouseEvent | google.maps.IconMouseEvent> =
    new EventEmitter<google.maps.MouseEvent | google.maps.IconMouseEvent>();
  @Output()
  public applyMarkerClicked: EventEmitter<IMapMarker> = new EventEmitter<IMapMarker>();
  @Output()
  public clearMarkerClicked: EventEmitter<IMapMarker> = new EventEmitter<IMapMarker>();

  @Input() disabled?:boolean = false;
  @Input() placeholder?:string = 'Enter Location';

  public bounds?: LatLngBounds;
  public loadingMaps: boolean = true;
  private active: boolean = true;
  private listeners: Array<google.maps.MapsEventListener> = [];
  private rawMap?: google.maps.Map;
  private _placesService?: google.maps.places.PlacesService;

  constructor(public mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.markers && changes.markers.currentValue) {
      this.markers = changes.markers.currentValue;
      await this.fitBounds();
    }

  }

  public mapClicked($event: google.maps.MouseEvent | google.maps.IconMouseEvent): void {
    this.mapClick.emit($event);
  }

  public async ngOnInit(): Promise<void> {
    await this.fitBounds();
  }
  public ngOnDestroy(): void {
    this.active = false;
    if (this.listeners && this.listeners.length > 0) {
      this.listeners.forEach((t: google.maps.MapsEventListener) => t.remove());
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.mapsAPILoader.load();
    if (this.searchBox) {
      const autocomplete = new google.maps.places.Autocomplete(this.searchBox.nativeElement);
      this.listeners.push(autocomplete.addListener('place_changed', this.placeChanged(autocomplete)));
    }
  }

  public mapReadyHandler(map: google.maps.Map): void {
    this.rawMap = map;
    this._placesService = new google.maps.places.PlacesService(this.rawMap);
    this.listeners.push(map.addListener('click', (e: google.maps.MouseEvent) => {
      this.ngZone.run(() => {
        this.mapClicked(e);
      });
    }));
  }

  private placeChanged(autocomplete: google.maps.places.Autocomplete): () => void {
    return () => {
      this.ngZone.run(() => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.placeSelected.emit(place);
      });

    };
  }

  private async fitBounds(): Promise<void> {
    this.loadingMaps = true;
    await this.mapsAPILoader.load();
    this.loadingMaps = false;
    if (this.markers && this.markers.some((t: IMapMarker | undefined) => !!t) && this.markers.length > 1) {
      this.bounds = (new google.maps.LatLngBounds()) as LatLngBounds;
      this.markers.forEach((marker: IMapMarker | undefined) => {
        if (marker) {
          this.bounds?.extend(marker.position);
        }
      });
    }
  }
}
