import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUpdateUser } from '../models/update-user.model';
import { environment } from 'src/environments/environment';
import { ErrorDetails } from '../models';
import { IUser } from '../models/user.model';
import { Byte } from '@angular/compiler/src/util';
import { IUploadImage } from '../models/upload-image.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private readonly _resourceUrl: string = environment.serverApiUrl + '/users';

    public constructor(private readonly _http: HttpClient) {}

    public async update(user: IUpdateUser): Promise<void> {
        try {
            return await this._http.put<void>(this._resourceUrl, user).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async uploadProfileImage(uploadImage: IUploadImage): Promise<void> {
        try {
            await this._http.post<void>(environment.serverApiUrl + '/upload-profile-pictures', uploadImage).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async uploadBackgroundImage(uploadImage: IUploadImage): Promise<void> {
        try {
            const res = await this._http.post<void>(this._resourceUrl + '/upload-background-image', uploadImage).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async get(id: number): Promise<IUser | undefined> {
        try {
            return await this._http.get<IUser>(this._resourceUrl + '?id=' + id).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
