import { HttpStatusCode } from './http-status-code.model';

export class ErrorDetails {
    public title: string;
    public status: HttpStatusCode | undefined;
    public detail: string | undefined;
    public timestamp: Date;
    public developerMessage: string | undefined;

    public constructor(title: string, message?: string, developerMessage?: string, status?: HttpStatusCode) {
        this.title = title;
        this.status = status;
        this.detail = message;
        this.timestamp = new Date();
        this.developerMessage = developerMessage;
    }
}
