import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validIdentificationNumber(): ValidatorFn {
    return (control: AbstractControl): { validIdentificationNumber: boolean } | null => {
        const identificationNumber: string = control.value;
        if (!identificationNumber) {
            return null;
        }
        if (identificationNumber.length !== 13) {
            return {
                validIdentificationNumber: true
            };
        }

        const birthYear = parseInt(identificationNumber.substring(0, 2), 10);
        const birthMonth = parseInt(identificationNumber.substring(2, 4), 10) - 1;
        const birthDay = parseInt(identificationNumber.substring(4, 6), 10);
        const birthDate = new Date(birthYear, birthMonth, birthDay);

        const idDate = birthDate.getDate();
        const idMonth = birthDate.getMonth();
        const idYear = birthDate.getFullYear().toString();

        if (!(idYear.substring(2) === identificationNumber.substring(0, 2) && idMonth === birthMonth && idDate === birthDay)) {
            return {
                validIdentificationNumber: true
            };
        }

        let total = 0;
        let checkSum = 0;
        let multiplier = 1;
        for (let i = 0; i < 13; ++i) {
            total = parseInt(identificationNumber.charAt(i), 10) * multiplier;
            if (total > 9) {
                total = parseInt(total.toString().charAt(0), 10) + parseInt(total.toString().charAt(1), 10);
            }
            checkSum = checkSum + total;
            multiplier = multiplier % 2 === 0 ? 1 : 2;
        }
        if (checkSum % 10 !== 0) {
            return {
                validIdentificationNumber: true
            };
        }

        return null;
    };
}
