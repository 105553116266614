import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonAlertService } from '../../../core/services/common-alert.service';
import { IProductModel, IResourceModel, IServiceModel } from 'src/app/core/models';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public products: Array<IProductModel> = [];
    public services: Array<IServiceModel> = [];
    public resources: Array<IResourceModel> = [];

    public constructor(
        private readonly _router: Router,
        private readonly _commonAlertService: CommonAlertService,
        private readonly _route: ActivatedRoute
    ) {}

    public async ngOnInit(): Promise<void> {
        this.products = await this._route.snapshot.data.productLinks;
        this.services = await this._route.snapshot.data.serviceLinks;
        this.resources = await this._route.snapshot.data.resourceLinks;
    }

    public async handleRouteToService(service: IResourceModel): Promise<void> {
        try {
            await this._router.navigate([''], { queryParams: { service: service.id } });
        } catch (e) {
            void this._commonAlertService.popup('Error routing to', service.title);
        }
    }

    public async handleRouteToProduct(product: IProductModel): Promise<void> {
        try {
            await this._router.navigate([''], { queryParams: { product: product.id } });
        } catch (e) {
            void this._commonAlertService.popup('Error routing to', product.title);
        }
    }

    public async handleRouteToResource(resource: IResourceModel): Promise<void> {
        try {
            await this._router.navigate([''], { queryParams: { resource: resource.id } });
        } catch (e) {
            void this._commonAlertService.popup('Error routing to', resource.title);
        }
    }
}
