<div nz-row class="navbar content-container">
    <div nz-col nzSpan="6" class="navbar-logo-container">
        <a href="">
            <img src="assets/images/logo/navbar-logo.png" class="navbar-logo"/>
        </a>
    </div>
    <div nz-col nzSpan="18" class="nav-link-container">
        <a 
            nz-button nzType="link" 
            class="nav-link text-size-four"
        >
            Features/Benefits
        </a>
        <a 
            nz-button nzType="link"
            class="nav-link text-size-four"
        >
            Subscriptions
        </a>
        <a 
            nz-button nzType="link"
            class="nav-link text-size-four"
        >
            Contact Us
        </a>
        <a 
            nz-button nzType="link"
            class="nav-link text-size-four"
        >
            Support
        </a>
        <a 
            nz-button nzType="link"
            class="nav-link text-size-four"
        >
            About
        </a>
        <button nz-button (click)="routeToLogin()" class="primary-button" nzType="primary">Log In</button>
    </div>
</div>