<div >
    <app-side-navigation [adminUser]="false"></app-side-navigation>
    <div class="main-content-body">
        <app-admin-navbar></app-admin-navbar>
        <div class="page-content">
            <nz-content>
                <router-outlet></router-outlet>
            </nz-content>
        </div>
    </div>
</div>