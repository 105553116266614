import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FooterComponent,
    HeaderComponent,
    NavbarComponent,
    OnboardingComponent,
    LoginComponent,
    BlankLayoutComponent,
    SideNavigationComponent,
    AdminLayoutComponent,
    AdminNavbarComponent,
    EmployeeLayoutComponent,
    WebsiteLayoutComponent
} from './components';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { SharedModule } from '../shared';

@NgModule({
    declarations: [
        HeaderComponent,
        NavbarComponent,
        FooterComponent,
        OnboardingComponent,
        OnboardingComponent,
        LoginComponent,
        BlankLayoutComponent,
        SideNavigationComponent,
        AdminLayoutComponent,
        AdminNavbarComponent,
        EmployeeLayoutComponent,
        WebsiteLayoutComponent
    ],
    imports: [
        CommonModule,
        NzGridModule,
        NzButtonModule,
        NzLayoutModule,
        RouterModule,
        NzMenuModule,
        NzCollapseModule,
        NzSpinModule,
        NzIconModule,
        NzModalModule,
        SharedModule
    ],
    exports: [HeaderComponent, NavbarComponent, FooterComponent, OnboardingComponent]
})
export class LayoutsModule {}
