import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorDetails } from '../models';
import { IGetSavedAnswer } from '../models/get-saved-answer.model';
import { IQuestionnaireResults } from '../models/questionnaire-results.model';
import { ISaveAnswers } from '../models/save-answers.model';
import { ISelfDiagnosticAnswerModel } from '../models/self-diagnostic-answer.model';
import { ISelfDiagnosticQuestionModel } from '../models/self-diagnostic-question.model';
const API_URL = environment.serverApiUrl;

@Injectable({
    providedIn: 'root'
})
export class SelfDiagnosticService {
    public constructor(private readonly _http: HttpClient) {}

    public async getStageQuestions(stage: number): Promise<ISelfDiagnosticQuestionModel | undefined> {
        try {
            const res = await this._http
                .get<ISelfDiagnosticQuestionModel>(API_URL + '/questions?diagnosticType=' + stage)
                .toPromise();
            return res;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getStageReport(stage: number): Promise<Array<ISelfDiagnosticAnswerModel>> {
        try {
            const answers: Array<ISelfDiagnosticAnswerModel> = [];

            // Dummy answers
            let counter = 0;
            while (counter < 10) {
                const questionAnswer: number = Math.floor(Math.random() * (4 - 1 + 1) + 1);
                const answer: ISelfDiagnosticAnswerModel = {
                    stage,
                    questionId: 'dssdsdsd3213233',
                    answer: questionAnswer
                };
                answers.push(answer);
                counter++;
            }

            return answers;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async saveStageAnswers(answers: ISaveAnswers): Promise<ISelfDiagnosticQuestionModel | undefined> {
        try {
            return await this._http.post<ISelfDiagnosticQuestionModel>(API_URL + '/save-answers', answers).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getSavedAnswers(reportId: number): Promise<Array<IGetSavedAnswer> | undefined> {
        try {
            return await this._http.get<Array<IGetSavedAnswer>>(API_URL + '/generate-report?reportId=' + reportId).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getUserQuestionniareResults(userId: number, stage: number): Promise<Array<IGetSavedAnswer> | undefined> {
        try {
            return await this._http
                .get<Array<IGetSavedAnswer>>(
                    API_URL + '/user-questionnaire-results?userId=' + userId + '&diagnosticType=' + stage
                )
                .toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getCompletedQuestionnaireList(userId: number): Promise<Array<IQuestionnaireResults> | undefined> {
        try {
            const res = await this._http
                .get<Array<IQuestionnaireResults>>(API_URL + '/user-questionnaire-results/find-all?id=' + userId)
                .toPromise();
            return res;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
