import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {ErrorDetails} from "../models";

@Injectable({
  providedIn: 'root'
})
export class MyAudienceService {
  private readonly surveyResourceUrl: string = environment.serverApiUrl + '/my-audiences';

  public constructor(
    private readonly http: HttpClient
  ) {}

  public async sendBulkMail(model: FormData, idealCustomerId: number | undefined): Promise<void> {
    try {
      const authToken: string | null = localStorage.getItem('jwtToken');
      if (authToken) {
        const options = {
          headers: new HttpHeaders({
            Authorization: authToken
          })
        };
        return  this.http.put<void>(this.surveyResourceUrl + `/${idealCustomerId}`, model, options).toPromise();
      }else {
        throw new ErrorDetails('A connection could not be established. Please contact an administrator.');
      }
    } catch (e) {
      if (e && e instanceof ProgressEvent) {
        throw new ErrorDetails(
          'Errror',
          'Failed to create Survey',
          'Failed to create Survey',
          400
        );
      }

      const error: HttpErrorResponse = e as HttpErrorResponse;
      throw new ErrorDetails(
        error.error.detail,
        error.error.title || error.error.detail,
        error.error?.status,
        error.error.timeStamp
      );
    }
  }

}
