import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IProductModel } from 'src/app/core/models';
import { ProductService } from 'src/app/core/services';

@Injectable({
    providedIn: 'root'
})
export class ProductLinksResolver implements Resolve<Array<IProductModel>> {
    public constructor(private readonly _productService: ProductService) {}

    public async resolve(): Promise<Array<IProductModel>> {
        return this._productService.getProductNames();
    }
}
