import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDetails } from '../models';
import { IServiceModel } from '../models/service.model';

@Injectable({
    providedIn: 'root'
})
export class ServiceService {
    public async getServiceNames(): Promise<Array<IServiceModel>> {
        const services: Array<IServiceModel> = [
            { id: 1, title: 'Service 1' },
            { id: 2, title: 'Service 2' },
            { id: 3, title: 'Service 3' },
            { id: 4, title: 'Service 4' }
        ];

        try {
            return services;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
