<div id="side-nav">
    <div class="nav-content">
        <div class="logo">
            <img src="../../../../assets/images/logo/lalooga-logo-clear-back.png" />
        </div>
        <div class="nav-list-container" *ngIf="user">
            <ul nz-menu *ngIf="userRole && userRole === 'EVENT_CREATOR' || userRole === 'DEVELOPER'" nzMode="inline" class="menu-list-container">
                <li nz-menu-item class="text-size-four" routerLink="/admin/dashboard">Dashboard</li>
                <li nz-menu-item class="text-size-four" routerLink="/admin/event-creation">Event Creation</li>
                <li nz-menu-item class="text-size-four" routerLink="/admin/event-management">Event Management</li>
                <li nz-menu-item class="text-size-four" routerLink="/admin/my-audience">My Audience</li>
                <li nz-menu-item class="text-size-four" routerLink="/admin/email-campaign">Email Campaign</li>
                <li nz-menu-item class="text-size-four" routerLink="/admin/survey-distribution">Survey Distribution</li>
                <div class="log-out-box">
                    <button nz-button class="quaternary-button" nzType="primary" (click)="logout()" [disabled]="isLoading">
                        <nz-spin *ngIf="isLoading" nzSimple></nz-spin>
                        <span *ngIf="!isLoading">
                            Log Out
                        </span>
                    </button>
                </div>
            </ul>
            <!-- <ul nz-menu *ngIf="!adminUser" nzMode="inline" class="menu-list-container">
                <li nz-menu-item class="text-size-four" nzSelected routerLink="/admin/dashboard">Dashboard</li>
                <li nz-menu-item class="text-size-four" (click)="routeTo('/admin/event-creation')">Event Creation</li>
                <li nz-menu-item class="text-size-four" routerLink="/admin/my-profile">My Profile</li>
                <li nz-menu-item class="text-size-four" routerLink="/admin/event-management">Event Management</li>
                <li nz-menu-item class="text-size-four" routerLink="/admin/reports">Reports</li>
                <div class="log-out-box">
                    <button nz-button class="quaternary-button" nzType="primary" (click)="logout()" [disabled]="isLoading">
                        <nz-spin *ngIf="isLoading" nzSimple></nz-spin>
                        <span *ngIf="!isLoading">
                            Log Out
                        </span>
                    </button>
                </div>
            </ul> -->
        </div>
    </div>
</div>
