import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError } from 'rxjs';
import { Amplify, Auth } from 'aws-amplify';
import { environment } from '../../../environments/environment';
import { IUserSignUpModel } from '../models/user-sign-up.model';
import { IUserConfirmSignUpModel } from '../models/user-confirm-sign-up.model';
import { ErrorDetails, IAuthenticateModel, IJwtTokenModel } from '../models';
import { JwtService } from './jwt.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ICognitoError } from '../models/cognito-error.model';
import { IAuthRes } from '../models/auth-res.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticateResourceService {
    private readonly _resourceUrl: string = environment.serverApiUrl + '/authenticate';

  public constructor(private readonly _http: HttpClient, private readonly _jwtService: JwtService) {}

  public async authenticate(model: IAuthenticateModel): Promise<IJwtTokenModel | undefined> {
    try {
      return this._http.post<IJwtTokenModel>(this._resourceUrl, model).toPromise();
    } catch (e) {
        if (e && e instanceof ProgressEvent) {
            throw new ErrorDetails(
                'A connection could not be established. Please contact an administrator.',
                'Contact Administrator',
                'A connection could not be established. Please contact an administrator.',
                400
            );
        }

        const error: HttpErrorResponse = e as HttpErrorResponse;

        throw new ErrorDetails(
            error.error.detail,
            error.error.title || error.error.detail,
            error.error?.status,
            error.error.timeStamp
        );
    }
  }
}
