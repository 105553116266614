import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorDetails } from '../models';
import { IEmployeeStageDetails } from '../models/employee-stage-details.model';
import { IMedals } from '../models/medals.model';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    private readonly _resourceUrl: string = environment.serverApiUrl;

    public constructor(private readonly _http: HttpClient) {}

    public async getMedals(level: number, round: number): Promise<IMedals> {
        const base = '../../../assets/images/performance-matrix/Levels/Level ';

        const paths = [];

        // Get previous level completed rounds;
        for (let levelCounter = 1; levelCounter < level; levelCounter++) {
            for (let roundCounter = 1; roundCounter < 10; roundCounter++) {
                paths.push(base + levelCounter + '/LEVEL ' + levelCounter + ' - ' + roundCounter + '.png');
            }
        }

        // Get current level completed rounds;
        for (let roundCounter = 1; roundCounter <= round; roundCounter++) {
            paths.push(base + level + '/LEVEL ' + level + ' - ' + roundCounter + '.png');
        }

        const medals: IMedals = {
            exploreNewThings: paths,
            completionAchievements: []
        };

        try {
            return medals;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getEmployeeStageDetails(userId: number): Promise<IEmployeeStageDetails | undefined> {
        try {
            return await this._http
                .get<IEmployeeStageDetails>(this._resourceUrl + '/employee-stage-details?userId=' + userId)
                .toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
