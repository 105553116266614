import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingIndicatorComponent, FormControlErrorComponent, IframelyEmbedComponent } from './components';
// import { NzLayoutModule } from 'ng-zorro-antd/layout';
// import { NzGridModule } from 'ng-zorro-antd/grid';
// import { NzFormModule } from 'ng-zorro-antd/form';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
// import { NzButtonModule } from 'ng-zorro-antd/button';
// import { NzCardModule } from 'ng-zorro-antd/card';
import { EnumToTextPipe } from './pipes/enum-to-text.pipe';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
// import { NzModalModule } from 'ng-zorro-antd/modal';
import { CommaToSemiCommaPipe } from './pipes/comma-to-semi-comma.pipe';
import { SelectLocationComponent } from './components/select-location/select-location.component';
import {AgmCoreModule} from "@agm/core";
import {UriPipe} from './pipes/uri.pipe';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@NgModule({
    declarations: [
        LoadingIndicatorComponent,
        FormControlErrorComponent,
        EnumToTextPipe,
        ErrorModalComponent,
        CommaToSemiCommaPipe,
        SelectLocationComponent,
        UriPipe,
        IframelyEmbedComponent
    ],
    imports: [
        CommonModule,
        // NzLayoutModule,
        // NzGridModule,
        // NzFormModule,
        // ReactiveFormsModule,
        // NzCheckboxModule,
        // NzButtonModule,
        // NzCardModule,
        // FormsModule,
        // NzModalModule,
        AgmCoreModule,
        NzSpinModule
    ],
    exports: [
        LoadingIndicatorComponent, 
        FormControlErrorComponent, 
        EnumToTextPipe, 
        ErrorModalComponent, 
        SelectLocationComponent, 
        UriPipe,
        IframelyEmbedComponent
    ]
})
export class SharedModule {}
