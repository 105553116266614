import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDetails } from '../models';
import { IResourceModel } from '../models/resource.model';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {
    public async getResourceNames(): Promise<Array<IResourceModel>> {
        const resources: Array<IResourceModel> = [
            { id: 1, title: 'News' },
            { id: 2, title: 'Blog' },
            { id: 3, title: 'Videos' },
            { id: 4, title: 'FAQs' }
        ];

        try {
            return resources;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
