import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IResourceModel } from 'src/app/core/models';
import { ResourceService } from 'src/app/core/services';

@Injectable({
    providedIn: 'root'
})
export class ResourceLinksResolver implements Resolve<Array<IResourceModel>> {
    public constructor(private readonly _resourceService: ResourceService) {}

    public async resolve(): Promise<Array<IResourceModel>> {
        return this._resourceService.getResourceNames();
    }
}
