import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-navbar',
    templateUrl: './admin-navbar.component.html',
    styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent {
    public refresh() {
        window.location.reload();
    }
}
