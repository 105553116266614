import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AccountsComponent} from './accounts/accounts.component';
import {AdminLayoutComponent, BlankLayoutComponent, WebsiteLayoutComponent} from './layouts';
import {CompanySurveyManagementModule} from "./company-survey-management";

const routes: Routes = [
  // {
  //     path: '',
  //     loadChildren: async () => import('./home/home.module').then(m => m.HomeModule),
  //     // component: WebsiteLayoutComponent
  //     component: BlankLayoutComponent
  // },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'survey',
    loadChildren: async () => import('./survey-fulfilment/survey-fulfilment.module').then(m => m.SurveyFulfilmentModule),
    component: BlankLayoutComponent
  },
  {
    path: 'auth',
    loadChildren: async () => import('./accounts/accounts.module').then(m => m.AccountsModule),
    component: AccountsComponent
  },
  // {
  //     path: '',
  //     loadChildren: async () => import('./accounts/accounts.module').then(m => m.AccountsModule),
  //     component: AccountsComponent
  // },
  // {
  //     path: 'home',
  //     loadChildren: async () => import('./home/home.module').then(m => m.HomeModule),
  //     component: WebsiteLayoutComponent
  // },

  // {
  //     path: 'tickets',
  //     loadChildren: async () => import('./ticket-purchase/ticket-purchase.module').then(m => m.TicketPurchaseModule),
  //     component: BlankLayoutComponent
  // },
  // {
  //     path: 'my-tickets',
  //     loadChildren: async () => import('./ticket-view/ticket-view.module').then(m => m.TicketViewModule),
  //     component: BlankLayoutComponent
  // },

    {
        path: 'admin/dashboard',
        loadChildren: async () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        component: AdminLayoutComponent
    },
    {
        path: 'admin/event-creation',
        loadChildren: async () => import('./event-creation/event-creation.module').then(m => m.EventCreationModule),
        component: AdminLayoutComponent
    },
    {
        path: 'admin/ticket-creation',
        loadChildren: async () => import('./ticket-creation/ticket-creation.module').then(m => m.TicketCreationModule),
        component: AdminLayoutComponent
    },
    {
        path: 'admin/special-guest-creation',
        loadChildren: async () => import('./special-guest-creation/special-guest-creation.module').then(m => m.SpecialGuestCreationModule),
        component: AdminLayoutComponent
    },
    {
        path: 'admin/event-management',
        loadChildren: async () => import('./event-management/event-management.module').then(m => m.EventManagementModule),
        component: AdminLayoutComponent
    },
    {
        path: 'admin/my-audience',
        loadChildren: async () => import('./my-audience/my-audience.module').then(m => m.MyAudienceModule),
        component: AdminLayoutComponent
    },
    {
        path: 'admin/email-campaign',
        loadChildren: async () => import('./email-campaign/email-campaign.module').then(m => m.EmailCampaignModule),
        component: AdminLayoutComponent
    },
    {
      path: 'admin/survey-distribution',
      loadChildren: async () => import('./survey-distribution/survey-distribution.module').then(m => m.SurveyDistributionModule),
      component: AdminLayoutComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
