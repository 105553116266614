import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDetails } from '../models';
import { ILevelModel } from '../models/level.model';
import { IRoundModel } from '../models/round.model';

@Injectable({
    providedIn: 'root'
})
export class PerformanceMatrixService {
    public async getRounds(): Promise<Array<IRoundModel>> {
        const rounds: Array<IRoundModel> = [
            {
                round: 1,
                completed: true,
                active: false
            },
            {
                round: 2,
                completed: true,
                active: true
            },
            {
                round: 3,
                completed: false,
                active: false
            },
            {
                round: 4,
                completed: false,
                active: false
            },
            {
                round: 5,
                completed: false,
                active: false
            },
            {
                round: 6,
                completed: false,
                active: false
            },
            {
                round: 7,
                completed: false,
                active: false
            },
            {
                round: 8,
                completed: false,
                active: false
            },
            {
                round: 9,
                completed: false,
                active: false
            },
            {
                round: 10,
                completed: false,
                active: false
            }
        ];

        try {
            return rounds;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getLevels(): Promise<Array<ILevelModel>> {
        const levels: Array<ILevelModel> = [
            {
                level: 1,
                completed: true,
                active: false,
                round: 1
            },
            {
                level: 2,
                completed: false,
                active: false,
                round: 1
            },
            {
                level: 3,
                completed: false,
                active: false,
                round: 1
            },
            {
                level: 4,
                completed: false,
                active: false,
                round: 1
            },
            {
                level: 5,
                completed: false,
                active: false,
                round: 1
            },
            {
                level: 6,
                completed: false,
                active: false,
                round: 1
            },
            {
                level: 7,
                completed: false,
                active: false,
                round: 1
            },
            {
                level: 8,
                completed: false,
                active: false,
                round: 1
            },
            {
                level: 9,
                completed: false,
                active: false,
                round: 1
            },
            {
                level: 10,
                completed: false,
                active: false,
                round: 1
            }
        ];

        try {
            return levels;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
