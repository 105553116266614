<div nz-grid>
    <div nz-row class="header-container">
        <div nz-col nzSpan="24">
            <p class="heading-three">Alert</p>
        </div>
    </div>

    <div nz-row class="body-container">
        <div nz-col nzSpan="24">
            <p class="text-size-four">
                {{errorMessage}}
            </p>
        </div>
    </div>
    
    <div nz-row class="button-container">
        <div nz-col nzSpan="24" *ngIf="reload" class="next-stage-button-container">
            <button nz-button class="senary-button" (click)="reloadPage()">Reload Page</button>
        </div>
        <div nz-col nzSpan="24" *ngIf="!reload" class="next-stage-button-container">
            <button nz-button class="senary-button" (click)="close()">Okay</button>
        </div>
    </div>
</div>