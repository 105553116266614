import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class IsAuthenticatedGuard implements CanActivate, CanLoad {
    public constructor(private readonly _jwtService: JwtService, private readonly _router: Router) {}

    public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.allowAccess();
    }

    public canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.allowAccess();
    }

    public allowAccess(): boolean {
        const token = this._jwtService.getJwtToken();

        if (token) {
            return true;
        }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this._router.navigate(['/unauthorized']);
        return false;
    }
}
