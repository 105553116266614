import { Pipe, PipeTransform } from '@angular/core';

function capitalizeFirstLetter(text: string) {
    return text[0].toUpperCase() + text.slice(1);
}

@Pipe({
    name: 'enumToText'
})
export class EnumToTextPipe implements PipeTransform {
    public transform(enumValue: string): string {
        if (enumValue) {
            const enumValues: Array<string> = enumValue.split('_');
            let enumToText = '';

            for (const eValue of enumValues) {
                enumToText += capitalizeFirstLetter(eValue.toLocaleLowerCase()) + ' ';
            }

            return enumToText;
        } else {
            return enumValue;
        }
    }
}
