import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtService } from '../services';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthExpiredInterceptor implements HttpInterceptor {
    public constructor(private readonly _jwtService: JwtService, private readonly _router: Router) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap(
                () => {
                    // Not applicable for a error interceptor.
                },
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                (err: unknown) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            this._jwtService.logout();
                            void this._router.navigate(['/']);
                        }
                    }
                }
            )
        );
    }
}
