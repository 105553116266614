import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorDetails } from '../models';
import { ICreateEmployeeReport } from '../models/create-employee-report.model';
import { IEmployeeReportlist } from '../models/employee-micro-coach-report-list.model';
import { IEmployeeReportPagination } from '../models/employee-report-pagination.model';
import { IEmployeeReport } from '../models/employee-report.model';
import { IEmployeeReportsOverviewList } from '../models/employee-reports-overview-list.model';
import { IUploadEvidence } from '../models/upload-evidence.model';
import { IUploadPdfRetrieval } from '../models/upload-pdf-retrieval.model';

const API_URL = environment.serverApiUrl;

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    private readonly _resourceUrl: string = environment.serverApiUrl;

    public constructor(private readonly _http: HttpClient) {}

    public async createEmployeeReport(report: ICreateEmployeeReport): Promise<IEmployeeReport | undefined> {
        try {
            const res = await this._http.post<IEmployeeReport>(this._resourceUrl + '/employee-reports', report).toPromise();
            return res;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async submitEvidence(evidence: IUploadEvidence): Promise<void> {
        try {
            const res = await this._http
                .post<IUploadEvidence>(this._resourceUrl + '/employee-reports/upload-evidence', evidence)
                .toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async uploadPdfEvidence(evidence: FormData): Promise<IUploadPdfRetrieval | undefined> {
        try {
            return await this._http.post<IUploadPdfRetrieval>(this._resourceUrl + '/upload-file', evidence).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getCoachEmployeeReports(
        coachId: number,
        searchTerm: string,
        pageNumber: number,
        pageSize: number
    ): Promise<IEmployeeReportPagination<IEmployeeReport> | undefined> {
        try {
            return await this._http
                .get<IEmployeeReportPagination<IEmployeeReport>>(
                    this._resourceUrl +
                        '/coach-employee-reports?coachId=' +
                        coachId +
                        '&searchTerm=' +
                        searchTerm +
                        '&pageNumber=' +
                        pageNumber +
                        '&pageSize=' +
                        pageSize
                )
                .toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getEmployeeReportByReportId(reportId: number): Promise<IEmployeeReport | undefined> {
        try {
            const res = await this._http
                .get<IEmployeeReport>(this._resourceUrl + '/employee-reports?employeeReportId=' + reportId)
                .toPromise();

            return res;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async approveReport(reportId: number): Promise<void> {
        try {
            const res = await this._http
                .put<void>(this._resourceUrl + '/employee-reports/approve?employeeReportId=' + reportId, null)
                .toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getEmployeeReports(
        userId: number,
        searchTerm: string,
        pageNumber: number,
        pageSize: number
    ): Promise<IEmployeeReportlist | undefined> {
        try {
            const res = await this._http
                .get<IEmployeeReportlist>(
                    this._resourceUrl +
                        '/employee-reports/find-all-by-user-id?userId=' +
                        userId +
                        '&searchTerm=' +
                        searchTerm +
                        '&pageNumber=' +
                        pageNumber +
                        '&pageSize=' +
                        pageSize
                )
                .toPromise();

            return res;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getAllEmployeeReportsByCompanyId(
        companyId: number,
        searchTerm: string,
        pageNumber: number,
        pageSize: number,
        diagnosticType: number
    ): Promise<IEmployeeReportsOverviewList | undefined> {
        try {
            return await this._http
                .get<IEmployeeReportsOverviewList>(
                    this._resourceUrl +
                        '/companies/find-all-employees-report-details?companyId=' +
                        companyId +
                        '&searchTerm=' +
                        searchTerm +
                        '&pageNumber=' +
                        pageNumber +
                        '&pageSize=' +
                        pageSize +
                        '&diagnosticType=' +
                        diagnosticType
                )
                .toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
