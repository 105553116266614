import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
    providedIn: 'root'
})
export class CommonAlertService {
    public constructor(private readonly _notificationService: NzNotificationService) {}

    public async popup(title: string, msg: string | undefined): Promise<void> {
        this._notificationService.blank(title || 'Notification', msg || '', {
            nzStyle: {
                width: '600px',
                marginLeft: '-265px'
            },
            nzClass: 'custom-alert'
        });
    }
}
