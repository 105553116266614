import { Component, Input, OnInit } from '@angular/core';
// @ts-ignore  
import { SpotifyGenerator } from "embedgenerator";

@Component({
  selector: 'app-iframely-embed',
  templateUrl: './iframely-embed.component.html',
  styleUrls: ['./iframely-embed.component.scss']
})
export class IframelyEmbedComponent implements OnInit {
  /** Get html via JSON API calls to /api/oembed or /api/iframely. */
  public htmlCode = '<div></div>';
  @Input() public url?: string;
  public isLoading = false;

  public async ngOnInit(): Promise<void> {
    this.isLoading = true;
    if (this.url) {
      SpotifyGenerator.embed(
        this.url
      ).then((url: any) => {
        if (url) {
          this.htmlCode = `<iframe src=\"${url}\" style=\"border: 0; width: 100%; height: 400px;\" allowfullscreen allow=\"encrypted-media; clipboard-write; fullscreen; picture-in-picture\"></iframe>`;
        }
      });
    }
    this.isLoading = false;
  }
}
