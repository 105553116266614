import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commaToSemiComma'
})
export class CommaToSemiCommaPipe implements PipeTransform {
    public transform(text: string): string {
        if (text.length > 0 && text.includes(',')) {
            const segments: Array<string> = text.split(',');
            let concat = '';
            let counter = 0;

            for (const seg of segments) {
                counter++;

                if (counter === segments.length) {
                    concat += seg;
                } else {
                    concat += seg + ';';
                }
            }

            return concat;
        } else {
            return text;
        }
    }
}
