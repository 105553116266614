import { AbstractControl, ValidatorFn } from '@angular/forms';

export function validUsername(): ValidatorFn {
    return (control: AbstractControl): { validUsername: boolean } => {
        const emailRegEx = RegExp('^[_.@A-Za-z0-9-]*$');

        if (!emailRegEx.test(control.value)) {
            return {
                validUsername: true
            };
        } else {
            return {
                validUsername: false
            };
        }
    };
}
