import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { JwtService } from '../services';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    public constructor(private readonly _jwtService: JwtService) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (AuthInterceptor.isNotBackendRequest(request)) {
            return next.handle(request);
        }

        const token = this._jwtService.getJwtToken();
        // const guid = this._jwtService.getJwtGuid();  approach wont work as the guid can be inbetween url
        let updateRequest = request;

        if (token) {
            const headers = request.headers.set('authorizationToken', token);
            // updateRequest = request.clone({ headers , url : request.url + `/${guid}`}); approach wont work as the guid can be inbetween url
            updateRequest = request.clone({ headers });
        }

        return next.handle(updateRequest);
    }

    private static isNotBackendRequest(request: HttpRequest<unknown>): boolean {
        return (
            !request ||
            !request.url ||
            (/^http/.test(request.url) && !(environment.serverApiUrl && request.url.startsWith(environment.serverApiUrl)))
        );
    }
}
