import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ErrorDetails } from '../models';
import { plainToClass } from 'class-transformer';

// eslint-disable-next-line func-style, prefer-arrow/prefer-arrow-functions
export function handleError(error: HttpErrorResponse): Observable<never> {
    if (error && error.error instanceof ProgressEvent) {
        throw new ErrorDetails('Network Error', 'A connection could not be established. Please contact an administrator.');
    }
    return throwError(plainToClass(ErrorDetails, error.error));
}
