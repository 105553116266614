import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDetails } from '../models';
import { IDepartmentFilter } from '../models/department-filter.model';
import { DepartmentType } from '../models/department.model';
import { IRoleFilter } from '../models/role-filter.model';
import { Roles } from '../models/role.model';

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    public async getDepartmentFilters(): Promise<Array<IDepartmentFilter>> {
        const departmentFilters: Array<IDepartmentFilter> = [{ id: 1, filter: DepartmentType.Tech }];

        try {
            return departmentFilters;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async getRoleFilters(): Promise<Array<IRoleFilter>> {
        const roleFilters: Array<IRoleFilter> = [{ id: 1, filter: Roles.User }];

        try {
            return roleFilters;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
