import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDetails } from '../models';
import { IProductModel } from '../models/product.model';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    public async getProductNames(): Promise<Array<IProductModel>> {
        const products: Array<IProductModel> = [
            { id: 1, title: 'Product 1' },
            { id: 2, title: 'Product 2' },
            { id: 3, title: 'Product 3' },
            { id: 4, title: 'Product 4' }
        ];

        try {
            return products;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
