import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorDetails, IAuthRes } from '../models';
import { IAddEmployee } from '../models/add-employee.model';
import { IEmployeelist } from '../models/employee-list.model';
import { ISetStatusModel } from '../models/set-status.model';
import { Status } from '../models/status.model';

@Injectable({
    providedIn: 'root'
})
export class EmployeesService {
    private readonly _resourceUrl: string = environment.serverApiUrl + '/employees';

    public constructor(private readonly _http: HttpClient) {}

    public async employees(
        filter: string = 'super',
        companyId: number,
        pageNumber: number,
        pageSize: number
    ): Promise<IEmployeelist | undefined> {
        try {
            const employees: IEmployeelist | undefined = await this._http
                .get<IEmployeelist>(
                    this._resourceUrl +
                        '?searchTerm=' +
                        filter +
                        '&companyId=' +
                        companyId +
                        '&pageNumber=' +
                        pageNumber +
                        '&pageSize=' +
                        pageSize
                )
                .toPromise();

            return employees;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async addEmployee(employee: IAddEmployee): Promise<IAuthRes | undefined> {
        try {
            return await this._http.post<undefined | IAuthRes>(this._resourceUrl, employee).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }

    public async setStatus(userId: number, status: Status): Promise<void> {
        try {
            const newStatus: ISetStatusModel = {
                userId,
                status
            };
            await this._http.put<void>(this._resourceUrl, newStatus).toPromise();
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    'A connection could not be established. Please contact an administrator.',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.detail,
                error.error.title || error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
