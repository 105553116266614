import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-error-modal',
    templateUrl: './error-modal.component.html',
    styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent {
    @Input() public errorMessage?: string;
    @Input() public reload?: boolean;

    public constructor(private readonly _modal: NzModalRef) {}

    public async close(): Promise<void> {
        this._modal.destroy();
    }

    public reloadPage() {
        window.location.reload();
    }
}
