import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
    public constructor(private readonly _router: Router) {}

    public async routeToLogin(): Promise<void> {
        await this._router.navigate(['/auth/login']);
    }
}
