  <div class="form" *ngIf="showSearch">
    <div class="map-search">
      <input placeholder={{placeholder}} *ngIf="disabled"
              type="text" disabled>

      <input placeholder={{placeholder}} *ngIf="!disabled"
              type="text" #searchMapInput [value]="searchTerm">
    </div>
    <!--    <div class="search">-->
    <!--      <i class="fas fa-search" style="font-size:20px;margin:3px;"></i>-->
    <!--      <input #searchMapInput type="text" [value]="searchTerm"/>-->
    <!--    </div>-->
  </div>
<ng-container *ngIf="!loadingMaps && !bounds">
  <agm-map [latitude]="options?.center?.lat||0"
           [longitude]="options?.center?.lng||0"
           [zoom]="options?.zoom||1"
           [disableDefaultUI]="true"
           (mapReady)="mapReadyHandler($event)">
    <ng-container *ngFor="let marker of markers;let i=index">
      <agm-marker *ngIf="marker"
                  [latitude]="marker?.position?.lat||0"
                  [longitude]="marker?.position?.lng||0"
                  [title]="marker.title||''"
                  [iconUrl]="marker.icon">
      </agm-marker>
    </ng-container>
    <agm-polyline *ngFor="let connection of connections">
      <agm-polyline-point
        *ngFor="let coordinate of connection"
        [latitude]="coordinate.lat"
        [longitude]="coordinate.lng">
      </agm-polyline-point>
    </agm-polyline>
  </agm-map>
</ng-container>
<ng-container *ngIf="!loadingMaps && bounds">
  <agm-map [latitude]="options?.center?.lat||0"
           [longitude]="options?.center?.lng||0"
           [zoom]="options?.zoom||1"
           [fitBounds]="bounds"
           [disableDefaultUI]="true"
           (mapReady)="mapReadyHandler($event)">
    <ng-container *ngFor="let marker of markers;let i=index">
      <agm-marker *ngIf="marker"
                  [latitude]="marker?.position?.lat||0"
                  [longitude]="marker?.position?.lng||0"
                  [title]="marker.title||''"
                  [iconUrl]="marker.icon">
      </agm-marker>
    </ng-container>
    <agm-polyline *ngFor="let connection of connections">
      <agm-polyline-point
        *ngFor="let coordinate of connection"
        [latitude]="coordinate.lat"
        [longitude]="coordinate.lng">
      </agm-polyline-point>
    </agm-polyline>
  </agm-map>
</ng-container>

<ng-container *ngIf="loadingMaps">
  <div>Google maps are being loading...</div>
</ng-container>
