import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IServiceModel } from 'src/app/core/models';
import { ServiceService } from 'src/app/core/services';

@Injectable({
    providedIn: 'root'
})
export class ServiceLinksResolver implements Resolve<Array<IServiceModel>> {
    public constructor(private readonly _serviceService: ServiceService) {}

    public async resolve(): Promise<Array<IServiceModel>> {
        return this._serviceService.getServiceNames();
    }
}
