import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutsModule } from './layouts';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { CoreModule } from './core';
import {AgmDirectionModule} from "agm-direction";
import { AgmCoreModule } from "@agm/core";
registerLocaleData(en);

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgxWebstorageModule.forRoot({ prefix: 'lalooga-admin', separator: '-' }),
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        NzLayoutModule,
        CoreModule,
        LayoutsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCWtfhGTy2FCwKJAkFJu_7bWZxWfuXa9JE',
            libraries: ['places']
        }),
        AgmDirectionModule
    ],
    providers: [{ provide: NZ_I18N, useValue: en_US }],
    bootstrap: [AppComponent]
})
export class AppModule {}
