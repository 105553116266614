<div class="footer">
    <div nz-row class="section-one content-container">
        <div nz-col nzSpan="12" class="social-container">
            <p class="heading-five">GoPeak</p>
            <p class="text-size-four footer-text-colour-one">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br/>
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            
            <div class="social-icon-container">
                <a class="social-icon-link">
                    <img src="../../../../assets/images/icons/facebook-white-outline.png" class="social-icon"/>
                </a>
                <a class="social-icon-link">
                    <img src="../../../../assets/images/icons/twitter-white-outline.png" class="social-icon"/>
                </a>
                <a class="social-icon-link">
                    <img src="../../../../assets/images/icons/instagram-white-outline.png" class="social-icon"/>
                </a>
                <a class="social-icon-link">
                    <img src="../../../../assets/images/icons/youtube-white-outline.png" class="social-icon"/>
                </a>
                <a class="social-icon-link">
                    <img src="../../../../assets/images/icons/call-white-outline.png" class="social-icon"/>
                </a>
            </div>
        </div>
        <div nz-col nzSpan="4" class="products-container">
            <p class="heading-three">Products</p>
            
            <div 
                *ngFor="let product of products"
                class="product-list-container"
            >
                <a 
                    class="list-item text-size-four"
                    (click)="handleRouteToProduct(product)"
                >
                    {{ product.title }}
                </a>
            </div>
        </div>
        <div nz-col nzSpan="4" class="services-container">
            <p class="heading-three">Services</p>

            <div 
                *ngFor="let service of services"
                class="service-list-container"
            >
                <a 
                    class="list-item text-size-four"
                    (click)="handleRouteToService(service)"
                >
                    {{ service.title }}
                </a>
            </div>
        </div>
        <div nz-col nzSpan="4" class="resources-container">
            <p class="heading-three">Resources</p>

            <div 
                *ngFor="let resource of resources"
                class="resource-list-container"
            >
                <a 
                    class="list-item text-size-four"
                    (click)="handleRouteToResource(resource)"
                >
                    {{ resource.title }}
                </a>
            </div>
        </div>
    </div>
    <div nz-row class="section-two content-container">
        <div nz-col nzSpan="12">
            <p class="text-size-five footer-text copy-right">2020 Go Peak. All Rights Reserved.</p>
        </div>

        <div nz-col nzSpan="12">
            <div class="privacy-and-terms-container">
                <a class="text-size-five footer-text privacy-and-terms">
                    Privacy Policy
                </a>
                <a class="text-size-five footer-text privacy-and-terms">
                    Terms of Service 
                </a>
            </div>
        </div>
    </div>
</div>