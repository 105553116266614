import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ErrorDetails } from '../models';
import { ICompanyCreationModel } from '../models/company-creation.model';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    private readonly _resourceUrl: string = environment.serverApiUrl + '/companies';

    public constructor(private readonly _http: HttpClient) {}

    public async create(companyCreationDetails: ICompanyCreationModel): Promise<void> {
        try {
            const res = await this._http.post<void>(this._resourceUrl, companyCreationDetails).toPromise();

            return res;
        } catch (e) {
            if (e && e instanceof ProgressEvent) {
                throw new ErrorDetails(
                    'A connection could not be established. Please contact an administrator.',
                    'Contact Administrator',
                    '',
                    400
                );
            }

            const error: HttpErrorResponse = e as HttpErrorResponse;

            throw new ErrorDetails(
                error.error.title || error.error.detail,
                error.error.detail,
                error.error?.status,
                error.error.timeStamp
            );
        }
    }
}
